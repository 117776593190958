<template>
  <div>
    <v-row>
      <v-col cols="12">
        <redirect class="mb-5" title="Dicounts" route="website.discount.automatic" />
        <div class="page-header">
          <Notification v-if="error" :messages="error" type="error" />
          <h2 class="page-title">Create automatic discount</h2>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <Code />
        <Options />
        <!-- <BuyXGetYOptions v-if="discount.discount_value_type == 'buy_x_get_y'" /> -->
        <CustomOptions />
        <Minimum />
        <UsageLimits />
        <CustomerEligibility />
        <ActiveDates />
      </v-col>
      <v-col cols="12" md="4" class="px-3 py-3">
        <Summary />
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn :loading="isLoading" @click="submit" color="primary">Save</v-btn>
      </v-col>
    </v-row>
    <modal model="modal" title="Active dates alert" :isLoading="turnOffBtn" :callback="submitModal">
      <template slot="content">
        <div>
          Only one automatic discount can be active at a time. Do you want to save this discount and end the overlapped
          one (right now or at the start time of this discount)?
        </div>
        <div>
          <v-radio-group v-model="agree">
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { type } from 'os';
import { required, minLength, maxLength, minValue, maxValue, email, helpers, decimal } from 'vuelidate/lib/validators';
import moment from 'moment';
import Code from '../components/Code';
import Options from '../components/Options';
import ActiveDates from '../components/ActiveDates';
import CustomerEligibility from '../components/CustomerEligibility';
import Minimum from '../components/Minimum';
import UsageLimits from '../components/UsageLimits';
import BuyXGetYOptions from '../components/BuyXGetYOptions';
import CustomOptions from '../components/CustomOptions';
import Summary from '../components/Summary';
import { discountService } from '@/apis/discount';
import { mapState } from 'vuex';

export default {
  components: {
    Code,
    Options,
    ActiveDates,
    CustomerEligibility,
    Minimum,
    UsageLimits,
    // BuyXGetYOptions,
    CustomOptions,
    Summary,
  },
  data() {
    return {
      turnOffBtn: false,
      setEndDate: false,
      usageLimit: false,
      discount: {
        allocation_limit: 0,
        allocation_method: 'across',
        customer_selection: 'all',
        discount_end_date: '',
        entitled_collection_ids: null,
        entitled_country_ids: null,
        entitled_product_ids: null,
        entitled_variant_ids: null,
        once_per_customer: false,
        prerequisite_collection_ids: null,
        prerequisite_customer_ids: null,
        prerequisite_product_ids: null,
        prerequisite_quantity_range: {},
        prerequisite_shipping_price_range: null,
        prerequisite_subtotal_range: {},
        prerequisite_to_entitlement_quantity_ratio: null,
        prerequisite_variant_ids: null,
        discount_start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        status: 'active',
        target_selection: 'all',
        target_type: 'line_item',
        discount_code: '',
        total_sale_count: 0,
        discount_type: 'automatic',
        usage_count: 0,
        usage_limit: -1,
        value: -100,
        discount_value_type: 'percentage',
        discount_value: 0,
      },
      discountTypes: [
        {
          title: 'Percentage',
          value: 'percentage',
        },
        {
          title: 'Fixed amount',
          value: 'fixed_amount',
        },
        {
          title: 'Free shipping',
          value: 'free_shipping',
        },
      ],
      isLoading: false,
      error: null,
      exists: null,
      modal: false,
      agree: true,
    };
  },
  validations: {
    discount: {
      discount_code: {
        required,
      },
      discount_value: {
        required,
      },
    },
  },
  created() {
    this.discount.discount_start_date = moment(new Date())
      .tz(this.pagePreference.standardTimezone ? this.pagePreference.standardTimezone : 'America/Los_Angeles')
      .format('YYYY-MM-DD HH:mm:ss');
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    discountOption: function() {
      return this.discountOptions.find(discount => discount.type == this.discount.discount_value_type);
    },
  },
  methods: {
    async checkExistsAutoActive() {
      this.isLoading = true;
      try {
        let data = await discountService.checkExistsAutoActive();
        this.exists = data.data.discount;
        if (this.exists && this.exists.length > 0) {
          this.modal = true;
        } else {
          this.createDiscount();
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = true;
        console.log(error);
      }
    },
    async submit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.checkExistsAutoActive();
      } else {
        this.isLoading = false;
      }
    },
    async createDiscount() {
      this.isLoading = true;
      try {
        // if (this.discount.discount_start_date) {
        //   this.discount.discount_start_date = moment(new Date(this.discount.discount_start_date))
        //     .utc()
        //     .format('YYYY-MM-DD HH:mm:ss');
        // }
        // if (this.discount.discount_end_date) {
        //   this.discount.discount_end_date = moment(new Date(this.discount.discount_end_date))
        //     .utc()
        //     .format('YYYY-MM-DD HH:mm:ss');
        // }
        let discountData = await discountService.create(this.discount);

        this.$router.push({ name: 'website.discount.automatic.update', params: { id: discountData.data.id } });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.error = error;
        console.log(error);
      }
    },
    async disableExisting() {
      try {
        this.exists[0].discount_status = 'expired';
        let discountData = await discountService.update(this.exists[0]);
        this.createDiscount();
      } catch (error) {
        console.log(error);
      }
    },
    submitModal() {
      this.isLoading = true;
      if (this.agree) {
        this.disableExisting();
      } else {
        this.modal = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
